import React, { Suspense, lazy } from "react";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";

// Lazy loading the components
const Home = lazy(() => import("./components/Home"));
const Aboutus = lazy(() => import("./components/Aboutus"));
const Contactus = lazy(() => import("./components/Contactus"));
const Blogs = lazy(() => import("./components/Blogs"));
const Awards = lazy(() => import("./components/Awards"));
const Gallery = lazy(() => import("./components/Gallery"));
const Projects = lazy(() => import("./components/Projects"));
const Blogdetails = lazy(() => import("./components/Blogdetails"));
const Projectdetails = lazy(() => import("./components/Projectdetails"));
const Terms = lazy(() => import("./components/Terms"));
const Polices = lazy(() => import("./components/Polices"));

function App() {
  return (
    <div>
      {/* Suspense wraps the Routes and provides a fallback while components load */}
      <Suspense fallback={
         <div className="preloader">
         <div className="preloader__image" />
       </div>
      }>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogdetails" element={<Blogdetails />} />
          <Route path="/awards" element={<Awards />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projectdetails" element={<Projectdetails />} />
          <Route path="/termsandconditions" element={<Terms />} />
          <Route path="/privacypolicy" element={<Polices />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
